<template>
  <DialogEmpty v-if="$siteIdent.startsWith('minilu')">
    <Form
      id="product-subscription-cancel-form"
      submit-label="shop.productSubscription.cancel.title"
      cancel-label="formFields.form.cancel"
      @submit="onSubmit(data.subscription, cancelReason, data.product.name)"
      @cancel="dialogStore.closeDialog(DialogResponses.CANCEL)"
    >
      <div class="pb-md flex flex-col max-h-[92vh]">
        <div class="flex justify-center">
          <img
            src="@/assets/images/minilu/minilu-subscription-cancel.svg"
            class="relative -top-xl"
          />
        </div>

        <div class="h-full">
          <div class="text-center mb-md">
            {{ t('shop.productSubscription.cancel.text') }}
          </div>
          <div class="font-bold text-center">
            {{ data.product.name }}
          </div>
          <div v-if="data.product.variantName" class="text-center mb-md">
            {{ data.product.variantName }}
          </div>
          <Dropdown
            id="cancelReason"
            v-model="cancelReason"
            :value="options[0]"
            :options="options"
            label=""
            placeholder="shop.productSubscription.cancel.placeholder"
            :name="t('shop.productSubscription.cancel.emptyError')"
            :allow-empty="true"
          ></Dropdown>
        </div>
      </div>
    </Form>
  </DialogEmpty>
  <DialogLocal v-else>
    <Form
      id="product-subscription-cancel-form"
      submit-label="shop.productSubscription.cancel.title"
      cancel-label="formFields.form.cancel"
      @submit="onSubmit(data.subscription, cancelReason, data.product.name)"
      @cancel="dialogStore.closeDialog(DialogResponses.CANCEL)"
    >
      <div class="pb-md flex flex-col max-h-[92vh]">
        <div class="h-full">
          <div class="text-center mb-md">
            {{ t('shop.productSubscription.cancel.text') }}
          </div>
          <div class="font-bold text-center">
            {{ data.product.name }}
          </div>
          <div v-if="data.product.variantName" class="text-center mb-md">
            {{ data.product.variantName }}
          </div>
          <Dropdown
            id="cancelReason"
            v-model="cancelReason"
            :value="options[0]"
            :options="options"
            label=""
            placeholder="shop.productSubscription.cancel.placeholder"
            name="cancelReason"
            :allow-empty="true"
          ></Dropdown>
        </div>
      </div>
    </Form>
  </DialogLocal>
</template>
<script setup lang="ts">
import { DialogResponses, useDialogStore } from '@/stores/useDialogStore';
import Form from '@/components/formFields/localDialogForm/localDialogForm.vue';
import type { Product } from '~/server/transformers/shop/product/types';
import { Dropdown } from '@/complib';
import type { ProductSubscriptionItem } from '~/server/transformers/shop/productSubscriptionListTransformer';
import { useProductSubscriptionCancel } from './useProductSubscriptionCancel';
import DialogLocal from '../components/dialogFrame/local/dialogFrame.vue';
import DialogEmpty from '../components/dialogFrame/empty/dialogFrame.vue';
const { t } = useTrans();
const dialogStore = useDialogStore();
dialogStore.setHeadline(t('shop.productSubscription.edit.title'));
const data = dialogStore.getDialogData<{
  subscription: ProductSubscriptionItem;
  product: Product;
  triggerReload: boolean;
}>();
const { cancelReason, options, onSubmit } = useProductSubscriptionCancel();
</script>
