import { useSecureSessionPost } from '~/composables/dataFetching/genericFetchers';
import type { ProductSubscriptionItem } from '~/server/transformers/shop/productSubscriptionListTransformer';
import { DialogResponses, useDialogStore } from '@/stores/useDialogStore';
import { useToastStore } from '@/stores/useToastStore';
import { handleLoadingError } from '~/utils/handleLoadingError';

export function useProductSubscriptionCancel() {
  const { t } = useTrans();
  const dialogStore = useDialogStore();
  const cancelReason = ref<string>();
  const options = [
    {
      label: t('shop.productSubscription.cancel.option1'),
      value: t('shop.productSubscription.cancel.option1'),
    },
    {
      label: t('shop.productSubscription.cancel.option2'),
      value: t('shop.productSubscription.cancel.option2'),
    },
    {
      label: t('shop.productSubscription.cancel.option3'),
      value: t('shop.productSubscription.cancel.option3'),
    },
    {
      label: t('shop.productSubscription.cancel.option4'),
      value: t('shop.productSubscription.cancel.option4'),
    },
  ];

  async function onSubmit(
    subscription: ProductSubscriptionItem,
    reason: string,
    productName: string,
  ) {
    try {
      const site = useSiteIdent();
      const toastStore = useToastStore();
      const currentSubscription = ref(subscription);
      const response = await useSecureSessionPost<string>(
        `/api/${site}/shop/product/productSubscription/update/${subscription.id}`,
        {
          interval: subscription.interval,
          canceled: true,
          cancelReason: reason,
        },
      );
      if (response) {
        currentSubscription.value.canceled = true;
        toastStore.warning({
          text: t('shop.productSubscription.deactivate.notification', {
            name: productName,
          }),
        });

        dialogStore.closeDialog(DialogResponses.SUCCESS);
      } else {
        dialogStore.closeDialog(DialogResponses.CANCEL);
      }
    } catch (e: any) {
      dialogStore.closeDialog(DialogResponses.CANCEL);
      handleLoadingError(e);
    }
  }

  return {
    cancelReason,
    options,
    onSubmit,
  };
}
